.form-payku-PersonType {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  width: 410px;
  height: 600px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 8px 0px 8px rgba(0, 0, 0, 0.25);
}

.p-div-PersonType {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 360px;
  justify-content: space-between;
}

.radio-toolbar-PersonType {
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.radio-toolbar-PersonType input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar-PersonType label {
  display: inline-block;
  background-color: #fff;
  padding: 50px 55px;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border: 2px solid #ddd;
  transition: 0.3s;
  margin: 0;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.error-div-PersonType {
  width: 85%;
  margin-top: 5px;
}

.radio-toolbar-PersonType label:hover {
  border-color: #1c64ff;
}

.radio-toolbar-PersonType input[type="radio"]:checked+label {
  background-color: #1c64ff;
  color: #fff;
  border-color: #1c64ff;
}

.p-input-section-PersonType {
  text-align: left;
  width: 85%;
}


.p-input-PersonType {
  width: 100%;
  margin: 16px 0;
  padding: 0;
}

.btn-section-PersonType {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 40px;
  width: 90%;
}

.btn-section-PersonType .btn-PersonType-Siguiente {
  background-color: #5664D2;
  width: 90px;
  height: 40px;
  border-radius: 8px;
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);
}

.btn-section-PersonType .btn-PersonType-Siguiente:hover {
  background-color: #483DC8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);

}

.btn-section-PersonType .btn-PersonType-Volver {
  width: 90px;
  height: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);


}

.btn-section-PersonType .btn-PersonType-Volver:hover {
  background-color: #483DC8;
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);

}