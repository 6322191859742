.form-payku-PersonalInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0;
    width: 410px;
    height: 600px;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 8px 0px 8px rgba(0, 0, 0, 0.25);
    
}

.p-div-PersonalInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 360px;
    justify-content: space-around;
      
}

.p-input-section-PersonalInfo {
    text-align: left;
    width: 85%;
  }
  
.p-payku-PersonalInfo {
    font-size: 12px;
    display: flex;
  }

.p-input-PersonalInfo {
    width: 100%;
    margin: 16px 0;
  }

.btn-section-PersonalInfo{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 40px;
    width: 90%;
}

.btn-section-PersonalInfo
.btn-PersonalInfo-Siguiente{
     background-color: #5664D2; 
     width: 90px; 
     height: 40px;  
     border-radius: 8px;
     color: #fff;
     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);
}
.btn-section-PersonalInfo
.btn-PersonalInfo-Siguiente:hover{
 background-color: #483DC8;
 box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);

}

.btn-section-PersonalInfo
.btn-PersonalInfo-Volver{
  width: 90px; 
  height: 40px; 
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);


}
 
.btn-section-PersonalInfo
.btn-PersonalInfo-Volver:hover{
  background-color: #483DC8;
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);

 }