.App {
    text-align: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    background-color: #F7FAFC;
    font-family: 'Catamaran', sans-serif;
    position: relative;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.logo_content {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.logo-payku {
    width: 210px;
    padding-bottom: 24px;
}

.stripe {
    height: 40px;
    position: absolute;
    transform: skewY(-15deg);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 4px 0px 4px rgba(0, 0, 0, 0.1);
}

.s-one-left {
    top: 550px;
    left: 0;
    background-color: rgba(47, 7, 93, 0.8);
    width: 280px;
}

.s-two-left {
    top: 528px;
    left: 0;
    background-color: rgba(77, 57, 233, 0.8);
    width: 200px;
}

.s-three-left {
    top: 505px;
    left: 0;
    background-color: rgba(47, 7, 93, 0.8);
    width: 120px;
}

.s-four-left {
    top: 482px;
    left: 0;
    background-color: rgba(77, 57, 233, 0.8);
    width: 40px;
}

.s-one-right {
    bottom: 548px;
    right: 0;
    background-color: rgba(47, 7, 93, 0.8);
    width: 40px;
}

.s-two-right {
    bottom: 572px;
    right: 0;
    background-color: rgba(77, 57, 233, 0.8);
    width: 120px;
}

.s-three-right {
    bottom: 595px;
    right: 0;
    background-color: rgba(47, 7, 93, 0.8);
    width: 200px;
}

.s-four-right {
    bottom: 618px;
    right: 0;
    background-color: rgba(77, 57, 233, 0.8);
    width: 280px;
}

.animate__animated.animate__fadeIn {
    --animate-duration: 2s;
}

.animate__animated.animate__fadeOut {
    --animate-duration: 2s;
}

#canvas-wrap {
    position: relative;
    z-index: 1;
}

.card-payku {
    margin: auto;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 100vh;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 442px;
    height: 640px;
    box-shadow: 0px 8px 8px rgb(0 0 0 / 25%), 8px 0px 8px rgb(0 0 0 / 25%);
    z-index: 2;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}