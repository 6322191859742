.form-payku-ImagePerson {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0;
    width: 410px;
    height: 600px;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 8px 0px 8px rgba(0, 0, 0, 0.25);
  }
  
  .p-div-ImagePerson {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 360px;
    justify-content: space-around;
  }

  .p-upload-section {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 300px;
  }

  input[type="file"] {
    display: none;
  }

  .form-input-file-ImagePerson {
    display: flex;
    align-items: center;
    padding: 5px 0;
  }


  .p-error-ImagePerson{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;

  }
  .radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  .btn-upload-ImagePerson {
    border: none;
    padding: 20px 47px;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    width: 90px;
    height: 40px;
    font-size: 18px;
    border-radius: 8px;
    box-shadow: 2px 2px rgb(0 0 2 / 30%);
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif;  
    padding: 0;
    height: 45px;
    width: 45px;
    background-color: #5664D2;
    color: white;
    margin: 0 10px;
    display: flex;
    cursor: pointer;
    
}

  .p-file{
    font-family: 'Source Sans Pro', sans-serif;
    text-align: left;
    font-size: 18px;
  }

  .btn-section-ImagePerson{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 40px;
    width: 90%;
}

.btn-section-ImagePerson
.btn-ImagePerson-Crear{
     background-color: #5664D2; 
     width: 90px; 
     height: 40px;  
     border-radius: 8px;
     color: #fff;
     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);
}
.btn-section-ImagePerson
.btn-ImagePerson-Crear:hover{
 background-color: #483DC8;
 box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);

}

.btn-section-ImagePerson
.btn-ImagePerson-Volver{
  width: 90px; 
  height: 40px; 
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);


}
 
.btn-section-ImagePerson
.btn-ImagePerson-Volver:hover{
  background-color: #483DC8;
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 0px 5px rgba(0, 0, 0, 0.10);

 }

