.form-payku-Dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  width: 410px;
  height: 600px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 8px 0px 8px rgba(0, 0, 0, 0.25);
}

.p-div-Dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 360px;
  justify-content: space-around;
}

.p-payku-Dashboard {
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0 42px;
  text-align: justify;
  line-height: 24px;
  font-size: 16px;
}

.img-container-Dashboard {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  height: 160px;
  justify-content: center;
  align-items: center;
}

.img-logo-dashboard {
  width: 80px;
  margin: 0px 12px;
}

.img-logo.logo-redcompra {
  width: 200px;
}

.btn-section-Dashboard {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  padding: 0 25px;
  width: 90%;
}

.btn-section-Dashboard .btn-Dashboard {
  background-color: #5664D2;
  color: white;
  margin: 0 10px;
  border: none;
  padding: 20px 47px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  width: 90px;
  height: 40px;
  border-radius: 8px;
  box-shadow: 2px 2px rgb(0 0 2 / 30%);
  align-items: center;
  font-size: 13px;
  font-family: 'Source Sans Pro', sans-serif;
}

.btn-section-Dashboard .btn-Dashboard:hover {
  background-color: #4B3BC4;
}